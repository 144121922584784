// FIXME @JstnMcBrd Re-enable once you find a way to make lint happy without breaking everything
/* eslint-disable */

import { Equal, arrayEqual, identityEqual } from "@redotech/util/equal";
import { DependencyList, useCallback, useEffect, useRef } from "react";

/**
 * Compare React dependency lists
 */
export const dependencyListEqual: Equal<DependencyList> =
  arrayEqual(identityEqual);

export function useAbort(): AbortSignal {
  const abortController = useRef(new AbortController());
  useEffect(() => () => abortController.current.abort());
  return abortController.current.signal;
}

export function useDepsChanged(deps: DependencyList) {
  const ref = useRef<DependencyList | undefined>(undefined);
  if (!ref.current || !dependencyListEqual(ref.current, deps)) {
    ref.current = deps;
    return true;
  }
  return false;
}

/**
 * Returns function with stable reference for the passed callback.
 */
export function useHandler<F extends Function>(fn: F): F {
  const ref = useRef<F>();
  ref.current = fn;
  return useCallback(
    // Cannot define args using Parameters<F> when F extends Function
    // https://github.com/microsoft/TypeScript/issues/31881
    <F>(<Function>function (this: ThisType<F>, ...args: unknown[]) {
      return Reflect.apply(ref.current!, this, args);
    }),
    [],
  );
}

/**
 * useMemo, but allow deposing of values.
 * @param f Generator
 * @param dispose Disposer
 * @param deps Dependencies to re-run generator
 */
export function useMemoDispose<T>(
  f: () => T,
  dispose: (value: T) => void,
  deps: DependencyList,
) {
  const ref = useRef<{ deps: DependencyList; result: T } | undefined>();

  const disposeRef = () => {
    if (ref.current) {
      dispose(ref.current.result);
    }
  };

  useEffect(() => disposeRef, []);

  if (ref.current && dependencyListEqual(ref.current.deps, deps)) {
    return ref.current.result;
  }

  disposeRef();
  const result = f();
  ref.current = { deps, result };
  return result;
}

import { Input, InputProvider } from "@redotech/ui/form";
import { createContext, useMemo, useState } from "react";
import { useHandler } from "./hook";
import { useInc } from "./inc";

export type FormState =
  | typeof FormState.DISABLED
  | typeof FormState.ENABLED
  | typeof FormState.LOADING;

export namespace FormState {
  export const DISABLED = Symbol("disabled");
  export const ENABLED = Symbol("enabled");
  export const LOADING = Symbol("loading");
}

export const FormStateContext = createContext<FormState>(FormState.ENABLED);

export function useInput<V, C extends Input<V>>(
  provider: InputProvider<V, C>,
  initial: V,
): C {
  const [input, setInput] = useState(initial);
  return useInc(() => provider(initial, setInput), input, [initial]);
}

export function useOnInputChange<V>(
  input: Input<V>,
  fn: (value: V) => V,
): Input<V> {
  const setValue = useHandler((value: V) => {
    input.setValue(fn(value));
  });
  return useMemo(
    () => ({
      ...input,
      setValue,
    }),
    [input, setValue],
  );
}

/**
 * @file
 * @see {@link https://datatracker.ietf.org/doc/html/rfc6750#section-2 RFC 6750 §2 Authenticated Requests}
 */

import {
  AuthorizationField,
  Credentials,
  Request,
} from "@redotech/http/semantics";
import { Bijection } from "@redotech/util/bijection";

/**
 * @see {@link https://datatracker.ietf.org/doc/html/rfc6750#section-2 RFC 6750 §2 Authenticated Requests}
 */
export interface AuthMethod {
  read(request: Request): string;
  write(request: Request, token: string): void;
}

/**
 * @see {@link https://datatracker.ietf.org/doc/html/rfc6750#section-2.1 RFC 6750 §2.1 Authorization Request Header Field}
 */
export const authorizationHeaderAuthMethod: AuthMethod = {
  read(request: Request) {
    const credentials = AuthorizationField.read(request.headers);
    if (!credentials) {
      throw new Error("Missing authorization header");
    }
    return bearerCredentialsFormat.read(credentials);
  },
  write(request: Request, token: string) {
    AuthorizationField.write(
      request.headers,
      bearerCredentialsFormat.write(token),
    );
  },
};

export const BearerScheme = "Bearer";

/**
 * Bearer
 * @see {@link https://datatracker.ietf.org/doc/html/rfc6750#section-2.1 RFC 6750 §2.1 Authorization Request Header Field}
 */
export const bearerCredentialsFormat: Bijection<Credentials, string> = {
  read({ scheme, info }) {
    if (scheme !== BearerScheme) {
      throw new Error(`Expected scheme ${BearerScheme}, got ${scheme}`);
    }
    if (typeof info !== "string") {
      throw new Error(`Expected token`);
    }
    return info;
  },
  write(token) {
    return { scheme: BearerScheme, info: token };
  },
};

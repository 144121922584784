import { IncFunction, IncResult } from "@redotech/util/inc";
import { DependencyList, useRef } from "react";
import { useDepsChanged } from "./hook";

export function useInc<A, B>(
  fn: () => IncFunction<A, B>,
  input: A,
  deps: DependencyList,
): B {
  const ref = useRef<IncResult<A, B>>();
  ref.current = (useDepsChanged(deps) ? fn() : ref.current!.next)(input);
  return ref.current.value;
}

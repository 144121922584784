/**
 * @file
 * @see {@link https://www.rfc-editor.org/rfc/rfc2045 RFC 2046 Multipurpose Internal Mail Extensions (MIME) Part One: Format of Internet Message Bodies}
 */

import { Equal, stringEqual } from "@redotech/util/equal";
import { ImmutableMap, immutableMapEqual } from "@redotech/util/map";
import { format, parse } from "content-type";

/**
 * Top-level media type
 */
export class Type {
  constructor(readonly value: string) {}

  subtype(subtype: string) {
    return new MediaType(this.value, subtype);
  }

  toString() {
    return this.value;
  }
}

export const typeEquals: Equal<Type> = (a, b) => stringEqual(a.value, b.value);

export class Attribute {
  constructor(readonly value: string) {}

  toString() {
    return this.value;
  }

  static fromString(string: string) {
    if (/[ \x00-\x0f()<>@,;:\\"/[\]?=]/.test(string)) {
      throw new Error("Invalid character in attribute");
    }
    return new this(string.toLowerCase());
  }
}

export const attributeEqual: Equal<Attribute> = (a, b) =>
  stringEqual(a.value, b.value);

export class Parameters {
  constructor(readonly values: ImmutableMap<string, string>) {}

  *entries() {
    for (const [key, value] of this.values) {
      yield [new Attribute(key), value];
    }
  }

  get(attribute: Attribute): string | undefined {
    return this.values.get(attribute.value);
  }

  set(attribute: Attribute, value: string | undefined) {
    return new Parameters(
      value !== undefined
        ? this.values.set(attribute.value, value)
        : this.values.delete(attribute.value),
    );
  }

  static readonly EMPTY = new this(ImmutableMap.empty());
}

export const parametersEqual: Equal<Parameters> = (a, b) =>
  immutableMapEqual(stringEqual)(a.values, b.values);

/**
 * Type, subtype, and parameters
 */
export class MediaType {
  constructor(
    readonly type: string,
    readonly subtype: string,
    readonly parameters: Parameters = Parameters.EMPTY,
  ) {}

  withParameters(parameters: Parameters = Parameters.EMPTY) {
    return new MediaType(this.type, this.subtype, parameters);
  }

  toString() {
    return format({
      type: `${this.type}/${this.subtype}`,
      parameters: Object.fromEntries(
        [...this.parameters.entries()].map(
          ([attribute, value]) => <[string, string]>[String(attribute), value],
        ),
      ),
    });
  }

  static parse(value: string) {
    const parsed = parse(value);
    return new this(
      ...(<[string, string]>parsed.type.split("/")),
      new Parameters(
        ImmutableMap.fromEntries(Object.entries(parsed.parameters)),
      ),
    );
  }
}

export const mediaTypeEqual: Equal<MediaType> = (a, b) =>
  stringEqual(a.type, b.type) && stringEqual(a.subtype, b.subtype);

export function mediaTypeParametersEqual(
  ...attributes: Attribute[]
): Equal<MediaType> {
  return (a, b) =>
    mediaTypeEqual(a, b) &&
    attributes.every(
      (attribute) =>
        a.parameters.get(attribute) === b.parameters.get(attribute),
    );
}

export function optionalWhitespaceRead(string: string, start: number): number {
  while (string[start] === " " || string[start] === "\t") {
    start++;
  }
  return start;
}

export function optionalWhitespaceWrite() {
  return " ";
}

export function quotableRead(
  string: string,
  start: number,
): { end: number; value: string } {
  if (string[start] === '"') {
    return quotedStringRead(string, start);
  }
  return tokenRead(string, start);
}

export function quotableWrite(value: string): string {
  if (tokenInvalidRegex.test(value)) {
    return quotedStringWrite(value);
  }
  return tokenWrite(value);
}

export function quotedStringRead(
  string: string,
  start: number,
): { end: number; value: string } {
  if (string[start] !== '"') {
    throw new Error(`Expected quoted string at ${start}`);
  }
  start++;
  let result = "";
  for (let i = start; ; i++) {
    if (!string[i]) {
      throw new Error(`Unterminated quoted string at ${start}`);
    }
    if (string[i] === '"') {
      return { end: i + 1, value: result };
    }
    if (string[i] === "\\") {
      i++;
      if (!string[i]) {
        throw new Error(`Unterminated quoted string at ${start}`);
      }
    }
    result += string[i];
  }
}

const invalidQuotedRegex = /[^\t !-~\x80-\xff]/;

export function quotedStringWrite(value: string): string {
  const match = value.match(invalidQuotedRegex);
  if (match) {
    throw new Error(`Invalid quoted character ${match[0]}`);
  }
  return `"${value.replace(/[\\"]/g, "\\$&")}"`;
}

export type Token = string;

const tokenRegex = /[!#$%&'*+\-.^_`|~0-9A-Za-z]+/y;

export function tokenRead(
  string: string,
  start: number,
): { end: number; value: Token } {
  tokenRegex.lastIndex = start;
  const match = tokenRegex.exec(string);
  if (!match) {
    throw new Error(`Expected token at ${start}`);
  }
  return { end: tokenRegex.lastIndex, value: match![0] };
}

const tokenInvalidRegex = /[^!#$%&'*+\-.^_`|~0-9A-Za-z]/;

export function tokenWrite(value: Token) {
  if (!value) {
    throw new Error("Empty token");
  }
  const match = value.match(tokenInvalidRegex);
  if (match) {
    throw new Error(`Invalid token character: ${match[0]}`);
  }
  return value;
}

let key = 0;

// Using "Symbol" instead of "symbol" is necessary to make TypeScript happy
// eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
const keys = new WeakMap<object | Function | Symbol, number>();

export function getKey(value: unknown): string | number {
  switch (typeof value) {
    case "boolean":
      return `boolean:${value}`;
    case "bigint":
      return `bigint:${value}`;
    case "number":
      return value;
    case "string":
      return `string:${value}`;
    case "object":
      if (value === null) {
        return "null";
      }
    // fallthrough
    case "function":
    // fallthrough
    case "symbol":
      if (keys.has(value)) {
        return keys.get(value)!;
      }
      keys.set(value, key);
      return key++;
    case "undefined":
      return "undefined";
  }
}

import { useLayoutEffect, useMemo, useState } from "react";
import { useEventListener } from "./event";

export interface Size {
  height: number;
  width: number;
}

export function useSize(element: HTMLElement | null): Size | undefined {
  const [height, setHeight] = useState<number | undefined>();
  const [width, setWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    setHeight(element?.clientHeight);
    setWidth(element?.clientWidth);
  }, [element?.clientHeight, element?.clientWidth]);

  useEventListener(window, "resize", () => {
    setHeight(element?.clientHeight);
    setWidth(element?.clientWidth);
  });

  return useMemo(
    () =>
      height !== undefined && width !== undefined
        ? { height, width }
        : undefined,
    [height, width],
  );
}

import { useLayoutEffect, useRef, useState } from "react";

export function useEventListener<T extends EventTarget>(
  target: T | null,
  event: string,
  listener: EventListener,
  options?: AddEventListenerOptions | boolean,
) {
  const ref = useRef<EventListener>(listener);
  ref.current = listener;

  useLayoutEffect(() => {
    if (target == null) {
      return;
    }
    const listener: EventListener = function (
      this: any,
      ...args: Parameters<typeof ref.current>
    ) {
      ref.current.apply(this, args);
    };
    target.addEventListener(event, listener, options);
    return () => target.removeEventListener(event, listener, options);
  }, [event, target, options]);
}

export const useHtmlEventListener = useEventListener as <
  T extends HTMLElement,
  E extends keyof HTMLElementEventMap,
>(
  target: T | null,
  event: E,
  listener: (this: T, event: HTMLElementEventMap[E]) => any,
  options?: AddEventListenerOptions | boolean,
) => void;

export const useWindowEventListener = useEventListener as <
  E extends keyof WindowEventMap,
>(
  window: Window,
  event: E,
  listener: (this: Window, event: WindowEventMap[E]) => any,
  options?: AddEventListenerOptions | boolean,
) => void;

export function useLoaded(target: HTMLImageElement | null): boolean {
  const [load, setLoad] = useState(false);

  useLayoutEffect(() => {
    setLoad(!!target && target.complete);
  }, [target]);

  useHtmlEventListener(target, "load", () => {
    setLoad(true);
  });

  return load;
}

import * as classNames from "classnames";
import { ReactNode, memo } from "react";
import { Flex } from "./flex";
import * as labeledInputCss from "./labeled-input.module.css";
import { Text } from "./text";

export enum LabelTheme {
  NORMAL = "Normal",
  LIGHT = "Light",
  THIN = "Thin",
  THIN_BOLD = "Thin Bold",
}

export enum LabelPosition {
  ABOVE = "above",
  LEFT = "left",
  RIGHT = "right",
}

export enum LabelOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum LabelSize {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
}

const labelThemeClass = new Map<LabelTheme, string>();
labelThemeClass.set(LabelTheme.LIGHT, labeledInputCss.light);
labelThemeClass.set(LabelTheme.THIN, labeledInputCss.thin);
labelThemeClass.set(LabelTheme.THIN_BOLD, labeledInputCss.thinBold);

const labelPositionClass = new Map<LabelPosition, string>();
labelPositionClass.set(LabelPosition.ABOVE, labeledInputCss.vertical);

const labelSizeClass = new Map<LabelSize, string>();
labelSizeClass.set(LabelSize.EXTRA_SMALL, labeledInputCss.xs);
labelSizeClass.set(LabelSize.SMALL, labeledInputCss.sm);
labelSizeClass.set(LabelSize.MEDIUM, labeledInputCss.md);

export const LabeledInput = memo(function LabeledInput({
  label,
  id,
  children,
  description,
  position = LabelPosition.ABOVE,
  errors = [],
  theme = LabelTheme.NORMAL,
  orientation = LabelOrientation.HORIZONTAL,
  size,
  alignmentOverride,
  className,
}: {
  id?: string;
  label: ReactNode;
  children: ReactNode;
  description?: ReactNode | ReactNode[];
  position?: LabelPosition;
  theme?: LabelTheme;
  orientation?: LabelOrientation;
  size?: LabelSize;
  errors?: string[];
  alignmentOverride?: "center" | "start" | "end"; // temporary escape hatch for alignment issues, deserves a better solution
  className?: string;
}) {
  return (
    <div
      className={classNames(
        labeledInputCss.container,
        labelPositionClass.get(position),
        size ? labelSizeClass.get(size) : undefined,
        className,
        labelThemeClass.get(theme),
      )}
      style={{
        alignItems: alignmentOverride,
      }}
    >
      {position === LabelPosition.RIGHT ? (
        orientation == LabelOrientation.HORIZONTAL ? (
          <>
            {children}
            {description && (
              <Text as="div" fontSize="xs" textColor="tertiary">
                {description}
              </Text>
            )}
            {label && (
              <label
                className={classNames(
                  labeledInputCss.label,
                  size ? labelSizeClass.get(size) : undefined,
                )}
                htmlFor={id}
              >
                {label}
              </label>
            )}
          </>
        ) : (
          <>
            {children}
            <Flex dir="column" gap="xxs">
              {label && (
                <label
                  className={classNames(
                    labeledInputCss.label,
                    size ? labelSizeClass.get(size) : undefined,
                  )}
                  htmlFor={id}
                >
                  {label}
                </label>
              )}
              {description && (
                <Text as="div" fontSize="xs" textColor="tertiary">
                  {description}
                </Text>
              )}
            </Flex>
          </>
        )
      ) : undefined}
      {[LabelPosition.LEFT, LabelPosition.ABOVE].includes(position) && (
        <>
          {label && (
            <label
              className={classNames(
                labeledInputCss.label,
                size ? labelSizeClass.get(size) : undefined,
              )}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {children}
          {description && (
            <Text as="div" fontSize="xs" textColor="tertiary">
              {description}
            </Text>
          )}
        </>
      )}
      {errors.map((error, index) => (
        <div className={labeledInputCss.error} key={index}>
          {error}
        </div>
      ))}
    </div>
  );
});

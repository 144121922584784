/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-5 RFC 9110 5 Fields}
 */

import { MediaType } from "@redotech/media-type";
import { Bijection } from "@redotech/util/bijection";
import {
  Equal,
  objectEqual,
  stringCaseInsensitiveEqual,
} from "@redotech/util/equal";
import { Method } from "./methods";

/**
 * Field line
 */
export class FieldLine {
  constructor(
    readonly name: FieldName,
    readonly value: string,
  ) {}
}

/**
 * Field section
 */
export class FieldSection implements Iterable<FieldLine> {
  constructor(lines: Iterable<FieldLine> = []) {
    this.lines = [...lines];
  }

  private lines: FieldLine[];

  /**
   * Add field line
   */
  add(line: FieldLine) {
    this.lines.push(line);
  }

  /**
   * Get field values by name
   */
  getValues(name: FieldName): string[] {
    return this.lines
      .filter((field) => FieldName.equal(field.name, name))
      .map((field) => field.value);
  }

  /**
   * Set field values by name
   */
  setValues(name: FieldName, values: string[]) {
    this.lines = this.lines.filter(
      (field) => !FieldName.equal(field.name, name),
    );
    for (const value of values) {
      this.lines.push(new FieldLine(name, value));
    }
  }

  [Symbol.iterator]() {
    return this.lines[Symbol.iterator]();
  }
}

/**
 * Field name
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-5.1 RFC 9110 Field Names}
 */
export class FieldName {
  constructor(readonly value: string) {}

  normalized() {
    return this.value.toLowerCase();
  }

  toString() {
    return this.value;
  }

  static readonly equal: Equal<FieldName> = objectEqual({
    value: stringCaseInsensitiveEqual,
  });
}

export class FieldType<T> {
  constructor(
    readonly name: FieldName,
    private readonly format: FieldFormat<T>,
  ) {}

  read(fields: FieldSection): T {
    return this.format.read(fields.getValues(this.name));
  }

  write(fields: FieldSection, value: T): void {
    return fields.setValues(this.name, this.format.write(value));
  }
}

/**
 * Field format
 */
export interface FieldFormat<T> extends Bijection<string[], T> {}

export interface FieldValueFormat<T> extends Bijection<string, T> {}

export function singleFieldFormat<T>(
  valueFormat: FieldValueFormat<T>,
): FieldFormat<T | undefined> {
  return {
    read([fieldValue]: string[]): T | undefined {
      return fieldValue !== undefined
        ? valueFormat.read(fieldValue)
        : undefined;
    },
    write(value: T | undefined) {
      return value !== undefined ? [valueFormat.write(value)] : [];
    },
  };
}

export function listFieldFormat<T>(
  valueFormat: FieldValueFormat<T>,
): FieldFormat<T[]> {
  return {
    read(fieldValues: string[]): T[] {
      return fieldValues
        .flatMap((value) => value.split(/\s*,\s*/))
        .map((fieldValue) => valueFormat.read(fieldValue));
    },
    write(value: T[]) {
      return value.length
        ? [value.map((value) => valueFormat.write(value)).join(", ")]
        : [];
    },
  };
}

export const integerFieldValueFormat: FieldValueFormat<number> = {
  read(fieldValue: string) {
    return +fieldValue;
  },
  write(value: number) {
    return String(value);
  },
};

export const fieldNameValueFormat: FieldValueFormat<FieldName> = {
  read(fieldValue: string) {
    return new FieldName(fieldValue);
  },
  write(value: FieldName) {
    return value.value;
  },
};

export const methodFieldValueFormat: FieldValueFormat<Method> = {
  read(fieldValue: string) {
    return new Method(fieldValue);
  },
  write(value: Method) {
    return value.name;
  },
};

export const stringFieldValueFormat: FieldValueFormat<string> = {
  read(fieldValue: string) {
    return fieldValue;
  },
  write(value: string) {
    return value;
  },
};

export function symbolEnumFieldValueFormat<T extends symbol>(
  values: T[],
): FieldValueFormat<T> {
  for (const value of values) {
    if (value.description === undefined) {
      throw new Error(`Symbol has no description: ${String(value)}`);
    }
  }
  const stringToValue = new Map<string, T>(
    values.map((value) => [value.description!, value]),
  );
  return {
    read(fieldValue: string) {
      const value = stringToValue.get(fieldValue);
      if (value === undefined) {
        throw new Error(`Invalid value: ${fieldValue}`);
      }
      return value;
    },
    write(value: T) {
      return value.description!;
    },
  };
}

export const mediaFieldValueFormat: FieldValueFormat<MediaType> = {
  read(fieldValue: string) {
    return MediaType.parse(fieldValue);
  },
  write(value: MediaType) {
    return value.toString();
  },
};

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-5.6.7 RFC 9110 5.6.7}
 */
export const dateFieldValueFormat: FieldValueFormat<Temporal.Instant> = {
  read(fieldValue: string): Temporal.Instant {
    return new Date(fieldValue).toTemporalInstant();
  },
  write(value: Temporal.Instant): string {
    return new Date(value.epochMilliseconds).toUTCString();
  },
};

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-4.1 URI References}
 */
export const uriFieldValueFormat: FieldValueFormat<URL | string> = {
  read(fieldValue: string) {
    if (URL.canParse(fieldValue)) {
      return new URL(fieldValue);
    }
    return fieldValue;
  },
  write(value: URL | string) {
    return value.toString();
  },
};

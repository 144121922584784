import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  JSXElementConstructor,
  memo,
  MouseEvent,
} from "react";
import CircleSpinner from "../../circle-spinner.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { TextSizeValue } from "../../theme/typography";
import * as redoButtonThemeCss from "./redo-button-themes.module.css";
import * as redoButtonCss from "./redo-button.module.css";

export enum RedoButtonSize {
  EXTRA_SMALL = "xs",
  SMALL = "sm",
  REGULAR = "md",
  LARGE = "lg",
  EXTRA_LARGE = "xl",
}

export enum RedoButtonTheme {
  NORMAL = "Normal",
  DESTRUCTIVE = "Destructive",
  SUCCESS = "Success",
}

export enum RedoButtonHierarchy {
  PRIMARY = "Primary",
  SECONDARY = "Secondary",
  TERTIARY = "Tertiary",
  LINK_GRAY = "Link Gray",
}

/**
 * @param pending -- custom pending states can be achieved by passing spinner icon leading, new text, and disabled
 *
 * @param dangerousStyleThatShouldOnlyBeUsedInSpecificSituations -- this should only defined if we need the button to follow a merchant's branding.
 * Do not use in other situations.
 *
 * @param type -- if you are using an HTML form, you may care about setting this.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#type}
 *
 */
export interface RedoButtonProps {
  onClick?(event: MouseEvent<HTMLButtonElement>): void;
  size?: RedoButtonSize;
  theme?: RedoButtonTheme;
  hierarchy?: RedoButtonHierarchy;
  text?: string;
  IconLeading?: JSXElementConstructor<any> | "placeholder";
  IconTrailing?: JSXElementConstructor<any> | "placeholder";
  disabled?: boolean;
  pending?: boolean;
  buttonClassName?: string;
  buttonId?: string;
  buttonValue?: string;
  centerItems?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  form?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
}

/**
 * Figma
 * https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=3287-427074&t=p984S770InFMNsms-0
 */
export const RedoButton = memo(
  forwardRef(function RedoButton(
    {
      size = RedoButtonSize.SMALL,
      theme = RedoButtonTheme.NORMAL,
      hierarchy = RedoButtonHierarchy.TERTIARY,
      IconLeading,
      IconTrailing,
      text,
      onClick,
      disabled,
      pending,
      centerItems = true,
      // 3 below are used for quill toolbar options
      buttonClassName,
      buttonId,
      buttonValue,
      className,
      type = "button",
      form,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
    }: RedoButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const isIconOnly = !text;

    function wrappedIcon(
      Icon: JSXElementConstructor<any> | "placeholder",
      extraClasses?: string,
    ) {
      const icon = typeof Icon === "string" ? <div /> : <Icon />;

      return (
        <Flex
          align="center"
          className={classNames(
            redoButtonCss.iconContainer,
            redoButtonCss[size],
            extraClasses,
          )}
          justify="center"
        >
          {icon}
        </Flex>
      );
    }

    const fontSize = sizeToFontSize[size];

    const px = isIconOnly ? iconOnlySizeToPaddingX[size] : sizeToPaddingX[size];

    return (
      <div className={className}>
        <button
          className={classNames(
            redoButtonCss.button,
            disabled || pending ? redoButtonThemeCss.disabled : undefined,
            redoButtonCss[size],
            themeClass[theme],
            hierarchyClass[hierarchy],
            buttonClassName,
          )}
          disabled={disabled || pending}
          form={form}
          id={buttonId}
          onClick={onClick}
          ref={ref}
          style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
          type={type}
          value={buttonValue}
        >
          {pending && wrappedIcon(CircleSpinner, redoButtonCss.spinner)}
          <Flex
            align="center"
            className={classNames(
              redoButtonCss.buttonContent,
              pending && redoButtonCss.pending,
            )}
            gap={sizeToGap[size]}
            justify={centerItems ? "center" : "flex-start"}
            px={px}
          >
            {IconLeading && wrappedIcon(IconLeading)}
            {text && (
              <Text
                fontSize={fontSize}
                fontWeight="medium"
                overflow="hidden"
                px="xxs"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {text}
              </Text>
            )}
            {IconTrailing && wrappedIcon(IconTrailing)}
          </Flex>
        </button>
      </div>
    );
  }),
);

const themeClass: Record<RedoButtonTheme, string> = {
  [RedoButtonTheme.NORMAL]: redoButtonThemeCss.themeNormal,
  [RedoButtonTheme.DESTRUCTIVE]: redoButtonThemeCss.themeDestructive,
  [RedoButtonTheme.SUCCESS]: redoButtonThemeCss.themeSuccess,
};

const hierarchyClass: Record<RedoButtonHierarchy, string> = {
  [RedoButtonHierarchy.PRIMARY]: redoButtonThemeCss.hierarchyPrimary,
  [RedoButtonHierarchy.SECONDARY]: redoButtonThemeCss.hierarchySecondary,
  [RedoButtonHierarchy.TERTIARY]: redoButtonThemeCss.hierarchyTertiary,
  [RedoButtonHierarchy.LINK_GRAY]: redoButtonThemeCss.hierarchyLinkGray,
};

const sizeToPaddingX: Record<RedoButtonSize, SpacingValue> = {
  [RedoButtonSize.EXTRA_SMALL]: "md",
  [RedoButtonSize.SMALL]: "lg",
  [RedoButtonSize.REGULAR]: "lg",
  [RedoButtonSize.LARGE]: "lg",
  [RedoButtonSize.EXTRA_LARGE]: "xl",
};

const iconOnlySizeToPaddingX: Record<RedoButtonSize, SpacingValue> = {
  [RedoButtonSize.EXTRA_SMALL]: "sm",
  [RedoButtonSize.SMALL]: "sm",
  [RedoButtonSize.REGULAR]: "md",
  [RedoButtonSize.LARGE]: "md",
  [RedoButtonSize.EXTRA_LARGE]: "lg",
};

const sizeToFontSize: Record<RedoButtonSize, TextSizeValue> = {
  [RedoButtonSize.EXTRA_SMALL]: "xs",
  [RedoButtonSize.SMALL]: "xs",
  [RedoButtonSize.REGULAR]: "sm",
  [RedoButtonSize.LARGE]: "sm",
  [RedoButtonSize.EXTRA_LARGE]: "md",
};

const sizeToGap: Record<RedoButtonSize, TextSizeValue> = {
  [RedoButtonSize.EXTRA_SMALL]: "xs",
  [RedoButtonSize.SMALL]: "xs",
  [RedoButtonSize.REGULAR]: "xs",
  [RedoButtonSize.LARGE]: "xs",
  [RedoButtonSize.EXTRA_LARGE]: "sm",
};

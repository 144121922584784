import { useHtmlEventListener } from "@redotech/react-util/event";
import { useHandler } from "@redotech/react-util/hook";
import { useMounted } from "@redotech/react-util/util";
import {
  CSSProperties,
  Fragment,
  Key,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as transitionCss from "./transition.module.css";

type AnimationState =
  | typeof AnimationState.OFF
  | typeof AnimationState.QUEUED
  | typeof AnimationState.RUNNING;

namespace AnimationState {
  export const OFF = Symbol("off");
  export const QUEUED = Symbol("queued");
  export const RUNNING = Symbol("running");
}

/**
 * Skip animations for the first 500ms. This is useful for loading without a lot
 * of motion.
 */
export function useSkipAnimations(): string | undefined {
  const [skipAnimations, setSkipAnimations] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setSkipAnimations(false), 500);
    return () => clearTimeout(timeout);
  }, []);
  return skipAnimations ? transitionCss.skipAnimation : undefined;
}

/**
 *
 * @returns Animating, start, and cancel
 */
export function useAnimation(
  ref: RefObject<HTMLElement>,
): [boolean, (duration: number) => void, () => void] {
  const durationRef = useRef<number>();
  const [state, setState] = useState<AnimationState>(AnimationState.OFF);
  useLayoutEffect(() => {
    switch (state) {
      case AnimationState.QUEUED:
        void ref.current!.offsetWidth;
        setState(AnimationState.RUNNING);
        break;
      case AnimationState.RUNNING: {
        const timeout = setTimeout(
          () => setState(AnimationState.OFF),
          durationRef.current,
        );
        return () => clearTimeout(timeout);
      }
    }
    return;
  }, [state, ref]);
  const start = useHandler((duration: number) => {
    durationRef.current = duration;
    setState(AnimationState.QUEUED);
  });
  const cancel = useHandler(() => setState(AnimationState.OFF));
  return [state === AnimationState.RUNNING, start, cancel];
}

enum State {
  DEFAULT = 0,
  START = 1,
  RESET = 2,
  ANIMATING = 3,
}

/**
 * Note: Requires a re-render to size things
 */
function animateSize(
  property: "height" | "width",
  sizeFn: (element: HTMLElement) => number,
): (element: HTMLElement | null) => [CSSProperties, boolean] {
  return (element) => {
    const [state, setState] = useState(State.DEFAULT);

    // most recent size
    const previousSize = useRef<number | undefined>();
    // styles
    const [fixedSize, setFixedSize] = useState<number | undefined>();

    const reset = () => {
      previousSize.current = undefined;
      setState(State.DEFAULT);
      setFixedSize(undefined);
    };

    useHtmlEventListener(element, "transitionend", (event: TransitionEvent) => {
      if (event.propertyName !== property) {
        return;
      }
      reset();
    });

    useLayoutEffect(reset, [element]);

    useLayoutEffect(() => {
      if (!element) {
        return;
      }
      switch (state) {
        case State.DEFAULT: {
          const size = sizeFn(element);
          if (
            previousSize.current !== undefined &&
            previousSize.current !== size
          ) {
            setState(State.RESET);
            setFixedSize(previousSize.current);
          }
          previousSize.current = size;
          break;
        }
        case State.RESET:
          setState(State.ANIMATING);
          setFixedSize(previousSize.current);
          void sizeFn(element);
          break;
      }
    }, [element, state]);

    const styles = useMemo(
      () => (fixedSize !== undefined ? { [property]: `${fixedSize}px` } : {}),
      [fixedSize],
    );

    return [styles, state !== State.DEFAULT];
  };
}

export const useAnimateHeight = animateSize(
  "height",
  (element) => element.offsetHeight,
);

export const useAnimateWidth = animateSize(
  "width",
  (element) => element.offsetWidth,
);

/**
 * Similar to animateSize, but avoids a measurement re-render since one size is 0.
 */
function expandSize(
  property: "height" | "width",
  sizeFn: (element: HTMLElement) => number,
): (
  expanded: boolean,
  element: HTMLElement | null,
) => [CSSProperties, boolean] {
  return (expanded, element) => {
    const state = useRef(expanded);

    // expanded size
    const expandedSize = useRef<number | undefined>();
    // fixed size
    const [fixedSize, setFixedSize] = useState<number | undefined>();

    const reset = () => {
      expandedSize.current = undefined;
      setFixedSize(undefined);
    };

    useHtmlEventListener(element, "transitionend", (event: TransitionEvent) => {
      if (event.propertyName !== property) {
        return;
      }
      reset();
    });

    useLayoutEffect(reset, [element]);

    useLayoutEffect(() => {
      if (!element || state.current === expanded) {
        return;
      }
      if (expandedSize.current === undefined) {
        // reset to opposite state
        expandedSize.current = sizeFn(element);
        setFixedSize(expanded ? 0 : expandedSize.current);
      } else {
        // set to eventual state
        state.current = expanded;
        setFixedSize(expanded ? expandedSize.current : 0);
        void sizeFn(element);
      }
      /* Justin and Cannon: We can't figure out why, but the main page dropdowns only work when state.current is in the dependency array */
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [element, state.current, expanded, expandedSize.current]);

    const styles = useMemo(
      () => (fixedSize !== undefined ? { [property]: `${fixedSize}px` } : {}),
      [fixedSize],
    );

    return [styles, state.current !== expanded || fixedSize !== undefined];
  };
}

/**
 * Expand height from 0
 */
export const useExpandHeight = expandSize(
  "height",
  (element) => element.offsetHeight,
);

/**
 * Expand width from 0
 */
export const useExpandWidth = expandSize(
  "width",
  (element) => element.offsetWidth,
);

export type OverlapTransitionState =
  | typeof OverlapTransitionState.APPEAR
  | typeof OverlapTransitionState.ENTER
  | typeof OverlapTransitionState.EXIT;

export namespace OverlapTransitionState {
  export const APPEAR = Symbol("appear");
  export const ENTER = Symbol("enter");
  export const EXIT = Symbol("exit");
}

export interface OverlapTransitionFn<T> {
  (data: T, state: OverlapTransitionState, finished: () => void): ReactNode;
}

interface OverlapTransitionInstance<T> {
  readonly data: T;
  finished(): void;
}

function overlapTransitionInstances<T>() {
  return new Map<Key, OverlapTransitionInstance<T>>();
}

export function OverlapTransition<T>({
  key_,
  data = undefined!,
  children,
}: {
  key_: Key;
  data?: T;
  children: OverlapTransitionFn<T>;
}) {
  const mounted = useMounted();
  const [instances, setInstances] = useState(overlapTransitionInstances<T>);
  const finished = useCallback(
    function finished() {
      setInstances((all) => {
        if (all.get(key_)?.finished === finished) {
          all = new Map(all);
          all.delete(key_);
        }
        return all;
      });
    },
    [key_],
  );
  instances.delete(key_);
  instances.set(key_, { data, finished });
  return (
    <>
      {[...instances.entries()].map(([k, v]) => (
        <Fragment key={k}>
          {children(
            v.data,
            k !== key_
              ? OverlapTransitionState.EXIT
              : mounted
                ? OverlapTransitionState.ENTER
                : OverlapTransitionState.APPEAR,
            v.finished,
          )}
        </Fragment>
      ))}
    </>
  );
}

"use strict";

var impl = require("./impl.cjs"), internal = require("./chunks/MdVg7kc-.cjs");

Object.defineProperties(globalThis, internal.createPropDescriptors({
  Temporal: impl.Temporal
})), Object.defineProperties(Intl, internal.createPropDescriptors({
  DateTimeFormat: impl.DateTimeFormat
})), Object.defineProperties(Date.prototype, internal.createPropDescriptors({
  toTemporalInstant: impl.toTemporalInstant
}));
